import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
// Styles
import styles from './RoomNameInput.module.css';

const RoomNameInput = ({ inventory, onFormValueChange, selectedRoomIndex, roomsAreas, currentUserRole }) => {

  const customStyles = {
    container: (provided) => ({
      ...provided,
      minWidth: '100px',
      minHeight: '0',
      padding: '0px',
    }),
    control: (provided) => ({
      ...provided,
      border: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none', // Remove the default box shadow
      borderBottom: '1px solid #000',
      borderRadius: '0px',
      minHeight: '0',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '15px', // Make selected text size bigger
      fontWeight: 'bold',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '15px', // Make placeholder text size bigger
      fontWeight: 'bold', // Make placeholder text bold
      padding: '0px',
    }),
    dropdownIndicator: () => ({
      display: 'none', // Remove the dropdown indicator
    }),
    indicatorSeparator: () => ({
      display: 'none', // Remove the vertical line
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '15px', // Make selected text size bigger
      fontWeight: 'bold',
      padding: '0px',
    }),
    menu: (provided) => ({
      ...provided,
      width: '220px', // Set the desired width for the dropdown menu
    }),
  };

  const roomsOptions = roomsAreas.rooms.map((room, idx) => {
    return { value: room[`roomName${idx}`], label: room[`roomName${idx}`] }
  })



  const handleSelectChange = (newValue, actionMeta) => {
    if (newValue) {
      let newRooms = inventory.rooms.map((room, idx) => {
        if (idx === selectedRoomIndex) {
          return { ...room, roomName: newValue.value }
        }
        return room
      })
      onFormValueChange(`inventory.rooms`, 'set', newRooms);
    }
  };

  return (
    <CreatableSelect
      value={{ value: inventory.rooms[selectedRoomIndex].roomName, label: inventory.rooms[selectedRoomIndex].roomName }}
      styles={customStyles}
      options={roomsOptions}
      onChange={handleSelectChange}
      isDisabled={currentUserRole === 'viewer'}
    />
  );
};

export default RoomNameInput;