// Third party libraries
import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
// Components
import { TemplateOneDayParams } from '../template-one-day-params/Template-one-day-params';


// Styles
import styles from './TemplateParamsColumn.module.css';
import { TemplateTwoDaysParams } from '../template-two-days-params/Template-two-days-params';
import { TemplateTwoDays150Params } from '../template-two-days-150-params/Template-two-days-150-params';
import { TemplateLongOneDayParams } from '../template-long-one-day-params/Template-long-one-day-params';
import { TemplateLongTwoDayParams } from '../template-long-two-day-params/Template-long-two-day-params';
import { TemplateOOSOneDayParams } from '../template-oos-one-day-params/Template-oos-one-day-params';
import { TemplateOOSTwoDayParams } from '../template-oos-two-day-params/Template-oos-two-day-params';
import { TemplateThreeDays150Params } from '../template-three-days-150-params/Template-three-days-150-params';




export const TemplateParamsColumn = (
  { templateForm,
    highLightItem,
    template,
    company, department, rates, onCalcFormValueChange,
    resetLastTarget, setOptions, users, departments, longdistances,
    outOfStates, onChangeLongDistanceSelect, onChangeOutOfStateSelect,
    onChangeUnpDepartmentSelect, unpRates, longDistance, factors,
    lastTarget,
    setLastTarget,
    furniture,
    roomsAreas,
    setCalculationFormValue
  }) => {
  const calculationForm = templateForm.templateCalcObj;

  const renderLongDistancesOptions = () => {
    let longDistancesOptions = [];
    longDistancesOptions = longdistances.map((longdistance) => {
      return (
        <option key={nanoid(longdistances.length)} value={longdistance._id}>{longdistance.directionName}</option>
      );
    })
    return longDistancesOptions;
  }

  const renderOOSOptions = () => {
    let outOfStatesOptions = [];
    outOfStatesOptions = outOfStates.map((outOfState) => {
      return (
        <option key={nanoid(outOfState.length)} value={outOfState._id}>{outOfState.directionName}</option>
      );
    })
    return outOfStatesOptions;
  }


  const checkMarkCheckboxValueChange = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedCheckMarksNames = [...calculationForm.selectedMiscCheckMarks];
        var index = calculationForm.selectedMiscCheckMarks.indexOf(evt.target.name);
        if (index !== -1) {
          newSelectedCheckMarksNames.splice(index, 1);
          return onCalcFormValueChange(`selectedMiscCheckMarks`, 'set', newSelectedCheckMarksNames);
        }
        break;
      case 'false':
        return onCalcFormValueChange(`selectedMiscCheckMarks`, 'push', evt.target.name);
      default:
        break;
    }
  };


  const additionalServicesCheckboxValueChange = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedAdditionalServices = [...calculationForm.commonValues.selectedAdditionalServices];
        var index = calculationForm.commonValues.selectedAdditionalServices.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedAdditionalServices.splice(index, 1);
          return onCalcFormValueChange(`commonValues.selectedAdditionalServices`, 'set', newSelectedAdditionalServices);
        }
        break;
      case 'false':
        return onCalcFormValueChange(`commonValues.selectedAdditionalServices`, 'push', { key: evt.target.name, quantity: 1 });
      default:
        break;
    }
  }

  return (
    <div /* className={styles.tagsContainer} */>
      {
        {
          'oneDayPacking': <>
            <TemplateOneDayParams
              highLightItem={highLightItem}
              company={company}
              department={department}
              templateForm={templateForm}
              rates={rates}
              onCalcFormValueChange={onCalcFormValueChange}
              resetLastTarget={resetLastTarget}
              setOptions={setOptions}
              users={users}
              departments={departments}
              lastTarget={lastTarget}
              setLastTarget={setLastTarget}
              checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
              additionalServicesCheckboxValueChange={additionalServicesCheckboxValueChange}
              furniture={furniture}
              roomsAreas={roomsAreas}
              setCalculationFormValue={setCalculationFormValue}
            />
          </>,
          'twoDaysPacking': <>
            <TemplateTwoDaysParams
              highLightItem={highLightItem}
              company={company}
              department={department}
              templateForm={templateForm}
              rates={rates}
              onCalcFormValueChange={onCalcFormValueChange}
              resetLastTarget={resetLastTarget}
              setOptions={setOptions}
              users={users}
              departments={departments}
              lastTarget={lastTarget}
              setLastTarget={setLastTarget}
              checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
              additionalServicesCheckboxValueChange={additionalServicesCheckboxValueChange}
              furniture={furniture}
              roomsAreas={roomsAreas}
              setCalculationFormValue={setCalculationFormValue}
            />
          </>,
          'twoDays150Miles': <>
            <TemplateTwoDays150Params
              highLightItem={highLightItem}
              company={company}
              department={department}
              templateForm={templateForm}
              rates={rates}
              onCalcFormValueChange={onCalcFormValueChange}
              resetLastTarget={resetLastTarget}
              setOptions={setOptions}
              users={users}
              departments={departments}
              lastTarget={lastTarget}
              setLastTarget={setLastTarget}
              checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
              additionalServicesCheckboxValueChange={additionalServicesCheckboxValueChange}
              furniture={furniture}
              roomsAreas={roomsAreas}
              setCalculationFormValue={setCalculationFormValue}
            />
          </>,
          'threeDays150Miles': <>
            <TemplateThreeDays150Params
              highLightItem={highLightItem}
              company={company}
              department={department}
              templateForm={templateForm}
              rates={rates}
              onCalcFormValueChange={onCalcFormValueChange}
              resetLastTarget={resetLastTarget}
              setOptions={setOptions}
              users={users}
              departments={departments}
              lastTarget={lastTarget}
              setLastTarget={setLastTarget}
              checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
              additionalServicesCheckboxValueChange={additionalServicesCheckboxValueChange}
              furniture={furniture}
              roomsAreas={roomsAreas}
              setCalculationFormValue={setCalculationFormValue}
            />
          </>,
          'longOneDayPacking': <>
            <TemplateLongOneDayParams
              highLightItem={highLightItem}
              company={company}
              department={department}
              templateForm={templateForm}
              rates={rates}
              onCalcFormValueChange={onCalcFormValueChange}
              resetLastTarget={resetLastTarget}
              setOptions={setOptions}
              users={users}
              departments={departments}
              renderLongDistancesOptions={renderLongDistancesOptions}
              onChangeLongDistanceSelect={onChangeLongDistanceSelect}
              onChangeUnpDepartmentSelect={onChangeUnpDepartmentSelect}
              unpRates={unpRates}
              longDistance={longDistance}
              longdistances={longdistances}
              lastTarget={lastTarget}
              setLastTarget={setLastTarget}
              checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
              furniture={furniture}
              roomsAreas={roomsAreas}
            />
          </>,
          'longTwoDaysPacking': <>
            <TemplateLongTwoDayParams
              highLightItem={highLightItem}
              company={company}
              department={department}
              templateForm={templateForm}
              rates={rates}
              onCalcFormValueChange={onCalcFormValueChange}
              resetLastTarget={resetLastTarget}
              setOptions={setOptions}
              users={users}
              departments={departments}
              renderLongDistancesOptions={renderLongDistancesOptions}
              onChangeLongDistanceSelect={onChangeLongDistanceSelect}
              onChangeUnpDepartmentSelect={onChangeUnpDepartmentSelect}
              unpRates={unpRates}
              longDistance={longDistance}
              longdistances={longdistances}
              lastTarget={lastTarget}
              setLastTarget={setLastTarget}
              checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
              furniture={furniture}
              roomsAreas={roomsAreas}
            />
          </>,
          'oos1day': <>
            <TemplateOOSOneDayParams
              highLightItem={highLightItem}
              company={company}
              department={department}
              templateForm={templateForm}
              rates={rates}
              onCalcFormValueChange={onCalcFormValueChange}
              resetLastTarget={resetLastTarget}
              setOptions={setOptions}
              users={users}
              departments={departments}
              renderOOSOptions={renderOOSOptions}
              onChangeOutOfStateSelect={onChangeOutOfStateSelect}
              onChangeUnpDepartmentSelect={onChangeUnpDepartmentSelect}
              unpRates={unpRates}
              longDistance={longDistance}
              longdistances={longdistances}
              factors={factors}
              lastTarget={lastTarget}
              setLastTarget={setLastTarget}
              checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
              furniture={furniture}
              roomsAreas={roomsAreas}
            />
          </>,
          'oos2days': <>
            <TemplateOOSTwoDayParams
              highLightItem={highLightItem}
              company={company}
              department={department}
              templateForm={templateForm}
              rates={rates}
              onCalcFormValueChange={onCalcFormValueChange}
              resetLastTarget={resetLastTarget}
              setOptions={setOptions}
              users={users}
              departments={departments}
              renderOOSOptions={renderOOSOptions}
              onChangeOutOfStateSelect={onChangeOutOfStateSelect}
              onChangeUnpDepartmentSelect={onChangeUnpDepartmentSelect}
              unpRates={unpRates}
              longDistance={longDistance}
              longdistances={longdistances}
              factors={factors}
              lastTarget={lastTarget}
              setLastTarget={setLastTarget}
              checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
              furniture={furniture}
              roomsAreas={roomsAreas}
            />
          </>
        }[template.templateCalcType]
      }
    </div>
  );
};
