// Third party libraries
import PropTypes from 'prop-types';
// Components

import styles from './ExtraOptionsResults.module.css';


export const ExtraOptionsResults = (props) => {
  const {
    department = {},
    selectedChargesNames = [],
    trucksCount,
    scrollRefs,
    highLightItem,
    day=''
  } = props;


  return (
    <div>
      {((Object.keys(department).length !== 0) && department.extraOptions.charges
        && department.extraOptions.charges.length > 0 && selectedChargesNames
        && selectedChargesNames.length > 0) ?
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Extra options:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
          </p>
          <ul>
            {
              department.extraOptions.charges.map((charge, chargeIndex) => {
                let showCharge = false;
                showCharge = selectedChargesNames.some(item => item.key?.includes(charge[`uId${chargeIndex}`]));
                if (showCharge && (charge[`chargeName${chargeIndex}`] !== '' || charge[`amount${chargeIndex}`] !== '')) {
                  return (<li key={`chargeName${chargeIndex}`}>
                    <span className={`${(highLightItem === `chIdName${day}_${charge[`uId${chargeIndex}`]}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`chIdName${day}_${charge[`uId${chargeIndex}`]}`]}>{charge[`chargeName${chargeIndex}`]}</span> <span className={`${(highLightItem === `chIdAmount${day}_${charge[`uId${chargeIndex}`]}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`chIdAmount${day}_${charge[`uId${chargeIndex}`]}`]}>(${charge[`amount${chargeIndex}`]})</span>
                    {((charge[`isCountable${chargeIndex}`]) ? <> x <span className={`${(highLightItem === `chIdQty${day}_${charge[`uId${chargeIndex}`]}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`chIdQty${day}_${charge[`uId${chargeIndex}`]}`]}>{selectedChargesNames.find(item => item.key === charge[`uId${chargeIndex}`]).quantity}</span> </> : '')}
                    : <span className={`${(highLightItem === `chIdAmount${day}_${charge[`uId${chargeIndex}`]}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`chIdAmount${day}_${charge[`uId${chargeIndex}`]}`]} >${(((charge[`isCountable${chargeIndex}`])) ? selectedChargesNames.find(item => item.key === charge[`uId${chargeIndex}`]).quantity * charge[`amount${chargeIndex}`] : charge[`amount${chargeIndex}`])}</span>
                    {(charge[`perTruck${chargeIndex}`] ? <> x {trucksCount} truck(s)=<span className={`${(highLightItem === `chIdAmount${day}_${charge[`uId${chargeIndex}`]}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`chIdAmount${day}_${charge[`uId${chargeIndex}`]}`]}>${(charge[`amount${chargeIndex}`]) * trucksCount * selectedChargesNames.find(item => item.key === charge[`uId${chargeIndex}`]).quantity}</span> </> : '')}
                      {((charge[`period${chargeIndex}`] === 'hourly') ? '/h' : '')}
                  </li>)
                }
                else {
                  return ''
                }
              })
            }
          </ul>
        </li>
        :
        ""
      }
    </div>
  );
}

