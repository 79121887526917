// Third party libraries
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import * as immutable from 'object-path-immutable';
import { useHistory, Prompt } from 'react-router-dom';
import { isEqual } from 'lodash';
import { connectSocket, disconnectSocket } from '../../context/socket';
import { Icon } from '../Icon';
import { IconNames } from '../../util/const';
// Components
import { BtnSubmit } from '../btn-submit/Btn-submit';
import { ExtraOptions } from '../extra-options/Extra-options';
import { MiscCheckMarks } from '../misc-checkmarks/Misc-checkmarks';

import { FormContainer } from '../form-container/Form-container';
import { getUserId, getCompanyId } from '../../clientStore/authSlice/auth-slice';
import {
  getFurnitureSavingStatus,
  setFurnitureSavingStatus,
  setFurnitureLoadingStatus,
  getFurnitureLoadingStatus
} from '../../clientStore/furnitureSlice/furniture-slice';
import {
  saveFurniture,
  updateFurniture,
} from '../../clientStore/furnitureSlice/furniture-async-thunk';

// DataModel
import { furnitureDataModel } from '../../models/furniture';
// Utils
import { fetchedData, removeByIndex } from '../../util/utils';
//Styles
import styles from './FurnitureForm.module.css';
import { isFurnitureFormValid } from '../../util/formValidations';
import { getCompany, getCompanyLoadingStatus } from '../../clientStore/companySlice/company-slice';
import { loadCompany } from '../../clientStore/companySlice/company-async-thunk';
import { DepartmentRadiobuttons } from '../department-radiobuttons/Department-radiobuttons';
import { getDepartments, getProcessDepartmentsLoading } from '../../clientStore/departmentsSlice/departments-slice';
import { loadDepartments } from '../../clientStore/departmentsSlice/departments-async-thunk';

export const FurnitureForm = ({ furnitureData, formType = 'new', furnitureId = undefined }) => {
  const [selectedDepartment, setSelectedDepartment] = useState('default');
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(getUserId);
  const companyId = useSelector(getCompanyId);
  const savedStatus = useSelector(getFurnitureSavingStatus);
  const departments = useSelector(getDepartments);
  const loadingDepartmentsStatus = useSelector(getProcessDepartmentsLoading);

  var furniture = furnitureData ? furnitureData : furnitureDataModel;

  const [formValue, setFormValue] = useState({
    ...furniture,
    authorId: userId,
    companyId,
  });

  const unChangedFormValue = {
    ...furniture,
    authorId: userId,
    companyId,
  }

  useEffect(() => {
    if (loadingDepartmentsStatus === 'idle') {
      dispatch(loadDepartments());
    }
  }, []);

  useEffect(() => {
    if (savedStatus === 'successed') {
      setIsLoading(false);
      setIsFormDirty(false)
      window.location.reload();
    }
  }, [savedStatus, dispatch]);



  const formSubmit = (evt) => {
    evt.preventDefault();
    setIsFormDirty(false)
    if (isFurnitureFormValid(formValue)) {
      setIsLoading(true);
      switch (formType) {
        case 'new':
          //if furniture with the name exists in company inform user with message: Furniture with name ${furnitureName} already exists
          dispatch(saveFurniture(formValue));
          break;
        case 'edit':
          dispatch(updateFurniture(formValue, furnitureId));
          break;
        default:
          throw new Error('Invalid form type');
      }

      setFormValue({
        ...furnitureDataModel,
        authorId: userId,
        companyId,
      });
    }
    else {
      alert("Please fill all required fields or delete duplicate fields.")
    }
  };

  const onFormValueChange = (path, method, value) => {
    setFormValue(immutable[method](formValue, path, value));
  };

  const setItemsValue = (evt, index) => {
    onFormValueChange(`items.${index}.${evt.target.name}`, 'set', evt.target.value);
  }

  const addItem = (index) => {
    const newItem = {
      [`itemName${index}`]: '',
      [`rangeMin${index}`]: 0,
      [`rangeMax${index}`]: 0,
      [`mostCommonCuFt${index}`]: 0,
    }
    onFormValueChange(`items`, 'push', newItem);
  }

  const removeItem = (index) => {
    if (window.confirm('This action will remove item. Are you sure?')) {
      let newItems = removeByIndex([...formValue.items], index)
      onFormValueChange(`items`, 'set', newItems);
    }
  }

  return (
    <FormContainer>
      <Prompt
        when={isFormDirty}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <form className={styles.form}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #000000' }}>
          <h3 className={styles.title1}>Furniture</h3>



        </div>

        <section style={{ marginTop: '5px', marginLeft: '5px' }}>

          {formValue.items.map((item, idx) => (
            <div key={idx} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', borderBottom: '1px solid rgba(77, 182, 172, .5)' }}>
              <label
                className={styles.inputContainer}
                htmlFor={`itemName${idx}`}
              >
                <p className={styles.inputName}>Item name</p>
                <input
                  type="text"
                  name={`itemName${idx}`}
                  value={item[`itemName${idx}`]}
                  onChange={event => setItemsValue(event, idx)}
                  className={styles.nameInput}
                />
              </label>
              <div className={styles.vl}></div>
              <label htmlFor={`rangeMin${idx}`} className={styles.inputContainer}>Cu Ft Range:
                <input
                  type="number"
                  id={`rangeMin${idx}`}
                  name={`rangeMin${idx}`}
                  value={item[`rangeMin${idx}`]}
                  onChange={event => setItemsValue(event, idx)}
                  className={styles.input}
                />
                -
                <input
                  type="number"
                  id={`rangeMax${idx}`}
                  name={`rangeMax${idx}`}
                  value={item[`rangeMax${idx}`]}
                  onChange={event => setItemsValue(event, idx)}
                  className={styles.input}
                />
              </label>
              <div className={styles.vl}></div>
              <label htmlFor={`mostCommonCuFt${idx}`} className={styles.inputContainer}>Most common Cu Ft
                <input
                  type="number"
                  id={`mostCommonCuFt${idx}`}
                  name={`mostCommonCuFt${idx}`}
                  value={item[`mostCommonCuFt${idx}`]}
                  onChange={event => setItemsValue(event, idx)}
                  className={styles.input}
                />
              </label>
              <button
                onClick={
                  (evt) => {
                    evt.preventDefault();
                    removeItem(idx);
                  }
                }
                className={styles.removeExtraBtnList}><Icon name={IconNames.MINUS} />
              </button>
            </div>
          ))}
          <div style={{ borderBottom: '1px solid rgba(77, 182, 172, .5)', marginTop: '5px' }}>
            <button
              className={styles.extraBtn}
              onClick={
                (evt) => {
                  evt.preventDefault();
                  addItem(formValue.items.length);
                }
              }
            >
              Add item
              <Icon name={IconNames.PLUS} />
            </button>
          </div>

          <div style={{ display: 'flex', borderBottom: '1px solid rgba(77, 182, 172, .5)', alignItems: 'center' }}>
            <input
              type="checkbox"
              id="useSymbolBeforeItems"
              name="useSymbolBeforeItems"
              checked={formValue.useSymbolBeforeItems}
              onChange={event => onFormValueChange(`useSymbolBeforeItems`, 'set', event.target.checked)}
            />
            <p className={styles.inputName}>Use this symbol before the furniture items on the inventory list</p>

            <label
              className={styles.inputContainer}
              htmlFor={`furnitureItemsSymbol`}
            >
              <input
                type="text"
                name={`furnitureItemsSymbol`}
                value={formValue.furnitureItemsSymbol}
                onChange={event => onFormValueChange(`furnitureItemsSymbol`, 'set', event.target.value)}
                className={styles.input}
                disabled={!formValue.useSymbolBeforeItems}
              />
            </label>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(77, 182, 172, 0.5)', paddingLeft: '5px' }}>
            Format text:
            <label className={styles.inputContainer}>
              <input
                type="checkbox"
                id="bold"
                name="bold"
                checked={formValue.bold}
                onChange={event => onFormValueChange(`bold`, 'set', event.target.checked)}
              />
              <p className={styles.inputName}>Bold</p>
            </label>
            <label className={styles.inputContainer}>
              <input
                type="checkbox"
                id="italic"
                name="italic"
                checked={formValue.italic}
                onChange={event => onFormValueChange(`italic`, 'set', event.target.checked)}
              />
              <p className={styles.inputName}>Italic</p>
            </label>
            <label className={styles.inputContainer}>
              <input
                type="checkbox"
                id="underline"
                name="underline"
                checked={formValue.underline}
                onChange={event => onFormValueChange(`underline`, 'set', event.target.checked)}
              />
              <p className={styles.inputName}>Underline</p>
            </label>
          </div>

          <div>
            <div style={{ marginTop: '10px' }}>
              <label className={styles.label}>
                Department
                <select
                  className={styles.select}
                  value={selectedDepartment}
                  onChange={(evt) => setSelectedDepartment(evt.target.value)}
                >
                  <option value='default' key='none'>Default</option>
                  {
                    departments && departments.map((department) => {
                      return (
                        <option key={nanoid(departments.length)} value={department._id}>{department.departmentName}</option>
                      );
                    })
                  }
                </select>
              </label>
            </div>
            <div style={{ display: 'flex', borderBottom: '1px solid rgba(77, 182, 172, 0.5)', alignItems: 'center', }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '440px', paddingTop: '5px' }}>
                <DepartmentRadiobuttons
                  title={'Include Cu Ft per item on the inventory list '}
                  name='includeCuFtPerItem'
                  isChecked={formValue.inventoryListOptions?.[selectedDepartment]?.includeCuFtPerItem ?? false}
                  onChangeValue={(evt) => onFormValueChange(`inventoryListOptions.${selectedDepartment}.includeCuFtPerItem`, 'set', !formValue.inventoryListOptions?.[selectedDepartment]?.includeCuFtPerItem)}
                  firstValue='Yes'
                  secondValue='No'
                  nameStyles={{ padding: '5px', marginLeft: '0px' }}
                  radioContStyles={{ paddingTop: '5px' }}
                />
                <div className={styles.vl}></div>
              </div>
              <label
                className={styles.inputContainer}
                htmlFor={`cuftText`}
              >
                <input
                  type="text"
                  name={`cuftText`}
                  value={formValue.inventoryListOptions?.[selectedDepartment]?.cuftText ?? ''}
                  onChange={event => onFormValueChange(`inventoryListOptions.${selectedDepartment}.cuftText`, 'set', event.target.value)}
                  className={styles.input}
                />
              </label>

              {formValue.inventoryListOptions?.[selectedDepartment]?.includeBoxesOnInv &&
                <><div className={styles.vl}></div>
                  <label style={{ display: 'flex' }}>
                    <input
                      type="checkbox"
                      id="bold"
                      name="bold"
                      checked={formValue.inventoryListOptions?.[selectedDepartment]?.combinePBOPBMBoxes}
                      onChange={event => onFormValueChange(`inventoryListOptions.${selectedDepartment}.combinePBOPBMBoxes`, 'set', event.target.checked)}
                    />
                    <p className={styles.inputName}>Combine PBO & PBM boxes</p>
                  </label>
                </>
              }
            </div>
            <div style={{ display: 'flex', borderBottom: '1px solid rgba(77, 182, 172, 0.5)', alignItems: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '415px', paddingTop: '5px' }}>
                <DepartmentRadiobuttons
                  title={'Include PBM boxes on the inventory list'}
                  name='includeBoxesOnInv'
                  isChecked={formValue.inventoryListOptions?.[selectedDepartment]?.includeBoxesOnInv ?? false}
                  onChangeValue={(evt) => {
                    let inventoryListOptions = formValue.inventoryListOptions;

                    inventoryListOptions[selectedDepartment].includeBoxesOnInv = !formValue.inventoryListOptions?.[selectedDepartment]?.includeBoxesOnInv;
                    inventoryListOptions[selectedDepartment].combinePBOPBMBoxes = false;
                    inventoryListOptions[selectedDepartment].convertToMediumBoxes = false;
                    inventoryListOptions[selectedDepartment].convertToMediumBoxesWB = false;
                    inventoryListOptions[selectedDepartment].adjustTotalCuFt = false;
                    inventoryListOptions[selectedDepartment].adjustTotalCuFtWB = false;
                    onFormValueChange(`inventoryListOptions`, 'set', inventoryListOptions)
                  }}
                  firstValue='Yes'
                  secondValue='No'
                  nameStyles={{ padding: '5px', marginLeft: '0px' }}
                  radioContStyles={{ paddingTop: '5px' }}
                />
                <div className={styles.vl}></div>
              </div>
              <label
                className={styles.inputContainer}
                htmlFor={`boxesText`}
              >
                <input
                  type="text"
                  name={`boxesText`}
                  value={formValue.inventoryListOptions?.[selectedDepartment]?.boxesText ?? ''}
                  onChange={event => onFormValueChange(`inventoryListOptions.${selectedDepartment}.boxesText`, 'set', event.target.value)}
                  className={styles.input}
                />
              </label>
              {formValue.inventoryListOptions?.[selectedDepartment]?.includeBoxesOnInv && <>
                <div className={styles.vl}></div>
                <label style={{ display: 'flex' }}>
                  <input
                    type="checkbox"
                    id="bold"
                    name="bold"
                    checked={formValue.inventoryListOptions?.[selectedDepartment]?.convertToMediumBoxes}
                    onChange={event => {
                      let inventoryListOptions = formValue.inventoryListOptions;
                      inventoryListOptions[selectedDepartment].convertToMediumBoxes = event.target.checked;
                      if (!event.target.checked) {
                        inventoryListOptions[selectedDepartment].convertToMediumBoxesWB = event.target.checked;
                      }
                      onFormValueChange(`inventoryListOptions`, 'set', inventoryListOptions)
                    }}
                  />
                  <p className={styles.inputName}>Convert to medium boxes</p>
                </label>

                {formValue.inventoryListOptions?.[selectedDepartment]?.convertToMediumBoxes && <>
                  <div className={styles.vl}></div>
                  <label style={{ display: 'flex' }}>
                    <input
                      type="checkbox"
                      id="bold"
                      name="bold"
                      checked={formValue.inventoryListOptions?.[selectedDepartment]?.adjustTotalCuFt}
                      onChange={event => {
                        let inventoryListOptions = formValue.inventoryListOptions;
                        inventoryListOptions[selectedDepartment].adjustTotalCuFt = event.target.checked;
                        if (inventoryListOptions[selectedDepartment].convertToMediumBoxesWB && event.target.checked) {
                          inventoryListOptions[selectedDepartment].adjustTotalCuFtWB = event.target.checked;
                        }
                        if (!event.target.checked) {
                          inventoryListOptions[selectedDepartment].adjustTotalCuFtWB = event.target.checked;
                        }
                        onFormValueChange(`inventoryListOptions`, 'set', inventoryListOptions)
                      }}
                    />
                    <p className={styles.inputName}>Adjust Total Cu Ft</p>
                  </label>
                </>}
              </>}
            </div>
            <div style={{ display: 'flex', borderBottom: '1px solid rgba(77, 182, 172, 0.5)', alignItems: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '415px', paddingTop: '5px' }}>
                <DepartmentRadiobuttons
                  title={'Include WB boxes on the inventory list'}
                  name='includeWBBoxesOnInv'
                  isChecked={formValue.inventoryListOptions?.[selectedDepartment]?.includeWBBoxesOnInv}
                  onChangeValue={(evt) => {
                    let inventoryListOptions = formValue.inventoryListOptions;
                    inventoryListOptions[selectedDepartment].includeWBBoxesOnInv = !formValue.inventoryListOptions?.[selectedDepartment]?.includeWBBoxesOnInv;
                    inventoryListOptions[selectedDepartment].convertToMediumBoxesWB = false;
                    inventoryListOptions[selectedDepartment].adjustTotalCuFtWB = false;
                    onFormValueChange(`inventoryListOptions`, 'set', inventoryListOptions)
                  }}
                  firstValue='Yes'
                  secondValue='No'
                  nameStyles={{ padding: '5px', marginLeft: '0px' }}
                  radioContStyles={{ paddingTop: '5px' }}
                />
                <div className={styles.vl}></div>
              </div>
              <label
                className={styles.inputContainer}
                htmlFor={`WBBoxesText`}
              >
                <input
                  type="text"
                  name={`WBBoxesText`}
                  value={formValue.inventoryListOptions?.[selectedDepartment]?.WBBoxesText ?? ''}
                  onChange={event => onFormValueChange(`inventoryListOptions.${selectedDepartment}.WBBoxesText`, 'set', event.target.value)}
                  className={styles.input}
                  disabled={!formValue.useSymbolBeforeItems}
                />
              </label>

              {(formValue.inventoryListOptions?.[selectedDepartment]?.includeWBBoxesOnInv) && <>
                <div className={styles.vl}></div>
                <label style={{ display: 'flex' }}>
                  <input
                    type="checkbox"
                    id="bold"
                    name="bold"
                    checked={formValue.inventoryListOptions?.[selectedDepartment]?.convertToMediumBoxesWB}
                    onChange={event => onFormValueChange(`inventoryListOptions.${selectedDepartment}.convertToMediumBoxesWB`, 'set', event.target.checked)}
                  />
                  <p className={styles.inputName}>Convert to medium boxes</p>
                </label>

                {formValue.inventoryListOptions?.[selectedDepartment]?.convertToMediumBoxesWB && <>
                  <div className={styles.vl}></div>
                  <label style={{ display: 'flex' }}>
                    <input
                      type="checkbox"
                      id="bold"
                      name="bold"
                      checked={formValue.inventoryListOptions?.[selectedDepartment]?.adjustTotalCuFtWB}
                      onChange={event => {
                        let inventoryListOptions = formValue.inventoryListOptions;
                        if (inventoryListOptions[selectedDepartment].convertToMediumBoxesWB) {
                          inventoryListOptions[selectedDepartment].adjustTotalCuFtWB = event.target.checked;
                        }
                        if (event.target.checked) {
                          inventoryListOptions[selectedDepartment].adjustTotalCuFt = event.target.checked;
                        }
                        onFormValueChange(`inventoryListOptions`, 'set', inventoryListOptions)
                      }}
                    />
                    <p className={styles.inputName}>Adjust Total Cu Ft</p>
                  </label>
                </>}
              </>
              }
            </div>
          </div>

          <BtnSubmit
            isActive={true}
            action={formSubmit}
            name={'Save'}
          />
        </section>
      </form>
    </FormContainer >
  );
};
