// InventoryContainer.jsx
import React, { createRef, useEffect, useRef, useState } from 'react';
import styles from './InventoryContainer.module.css';
import { BtnSubmit } from '../btn-submit/Btn-submit';
import { IconNames, Role } from '../../util/const';
import deleteIcon from './../../img/delete.png';
import { Icon } from '../Icon';
import RoomNameInput from '../room-name-input/RoomNameInput';
import { useDispatch, useSelector } from 'react-redux';
import StopSelect from '../stop-select/StopSelect';
import QuantityInput from '../quantity-input/QuantityInput';
import FurnitureNameInput from '../furniture-name-input/FurnitureNameInput';
import { generateRandomColor, hasRole, setPackingOptions } from '../../util/utils';
import CuFtInput from '../cuft-input/CuFtInput';
import BoxesInput from '../boxes-input/BoxesInput';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Loading } from '../loading/Loading';
import AddItemButton from '../add-item-button/AddItemButton';
import { getCalculation } from '../../clientStore/calculationSlice/calculation-slice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



const InventoryContainer = ({ calculation, onFormValueChange, furniture, roomsAreas, formSubmit, setUpdateableSave,
    savedStatus, applyInventory, formType, setIsAfterAutoSave, setIsFirstTime, resetLastTarget, currentUserRole }) => {
    const [itemChangeCount, setItemChangeCount] = React.useState(0);
    const [selectedRoomIndex, setSelectedRoomIndex] = React.useState(null);
    const [isSelectedRoomIndexSet, setIsSelectedRoomIndexSet] = useState(false);
    const [itemAdded, setItemAdded] = useState(false);
    const lastGoesItemRef = useRef(null);
    const lastStaysItemRef = useRef(null);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState('default');

    useEffect(() => {
        setSelectedDepartment(calculation.department._id);
    }, [calculation]);

    useEffect(() => {
        if (savedStatus === 'loading') {
            setShowLoader(true);
        } else {
            setShowLoader(false);
        }
    }, [calculation.inventory?.rooms, savedStatus]);



    useEffect(() => {
        if (itemChangeCount >= 3 && hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR])) {
            setUpdateableSave(false);
            setItemChangeCount(0);
            formSubmit(undefined, true);
            setIsAfterAutoSave(true);
            setIsFirstTime(false);
            // Reset the counter after autosave
        }
    }, [itemChangeCount]);

    useEffect(() => {
        // Check if selectedRoomIndex is a number before attempting to focus
        if (typeof selectedRoomIndex === 'number' && lastGoesItemRef.current && itemAdded) {
            lastGoesItemRef.current.focus();
            setItemAdded(false);
        }
    }, [calculation.inventory?.rooms[selectedRoomIndex]?.furnitureGoes]); // Include selectedRoomIndex in the dependency array

    useEffect(() => {
        // Check if selectedRoomIndex is a number before attempting to focus
        if (typeof selectedRoomIndex === 'number' && lastStaysItemRef.current && itemAdded) {
            lastStaysItemRef.current.focus();
            setItemAdded(false);
        }
    }, [calculation.inventory?.rooms[selectedRoomIndex]?.furnitureStays]); // Include selectedRoomIndex in the dependency array


    const onFormValueChangeWReset = (path, action, value) => {
        onFormValueChange(path, action, value);
        setLastModifiedGoesIndex(null);
        setLastModifiedStaysIndex(null);
        resetLastTarget();
    }

    const addRoom = (index) => {
        const newRoom = {
            roomName: '',
            furnitureGoes: [],
            furnitureStays: [],
            boxes: calculation.inventory.extraStops.map(() => { return { fragile: 0, small: 0, medium: 0, WB: 0 } }), //{ fragile: 0, small: 0, medium: 0, WB: 0 }
        };
        onFormValueChangeWReset(`inventory.rooms`, 'push', newRoom);
        setSelectedRoomIndex(index);
    }

    useEffect(() => {
        if (calculation.inventory?.rooms.length > 0 && roomsAreas && furniture && !isSelectedRoomIndexSet) {
            setSelectedRoomIndex(0);
            setIsSelectedRoomIndexSet(true);
        }
    }, [calculation.inventory?.rooms, roomsAreas, furniture]);

    const removeRoom = (index) => {
        const newRooms = calculation.inventory.rooms.filter((room, i) => i !== index);
        onFormValueChangeWReset(`inventory.rooms`, 'set', newRooms);
        setSelectedRoomIndex(null);
    }

    const addExtraStop = () => {
        const newStop = {
            name: '',
            color: generateRandomColor(),
        }

        const newInventory = JSON.parse(JSON.stringify(calculation.inventory));
        newInventory.extraStops.push(newStop);
        newInventory.rooms.forEach((room) => {
            room.boxes.push({ fragile: 0, small: 0, medium: 0, WB: 0 });
        });
        onFormValueChangeWReset(`inventory`, 'set', newInventory);
    }


    const removeExtraStop = () => {
        const newStops = calculation.inventory.extraStops.filter((stop, i) => i !== calculation.inventory.extraStops.length - 1);
        onFormValueChangeWReset(`inventory.extraStops`, 'set', newStops);
    }

    const changeExtraStopName = (event, index) => {
        const newStops = calculation.inventory.extraStops.map((stop, i) => {
            if (i === index) {
                return { ...stop, name: event.target.value }
            }
            return stop
        })
        onFormValueChangeWReset(`inventory.extraStops`, 'set', newStops);
    }

    const addItem = (type) => {
        const room = JSON.parse(JSON.stringify(calculation.inventory.rooms[selectedRoomIndex]));
        if (type === "Goes") {
            const furnitureGoes = room.furnitureGoes;
            furnitureGoes.push({
                name: '',
                cuft: 0,
                qty: 1,
                stopIndex: 0
            })
            onFormValueChangeWReset(`inventory.rooms.${selectedRoomIndex}.furnitureGoes`, 'set', furnitureGoes);
        } else {
            const furnitureStays = room.furnitureStays;
            furnitureStays.push({
                name: '',
                cuft: 0,
                qty: 1,
                stopIndex: 0
            })
            onFormValueChangeWReset(`inventory.rooms.${selectedRoomIndex}.furnitureStays`, 'set', furnitureStays);
        }
        setItemChangeCount(prevCount => prevCount + 1);
        setItemAdded(true);
    }

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const sourceList = source.droppableId === 'furnitureGoes' ? 'furnitureGoes' : 'furnitureStays';
        const destinationList = destination.droppableId === 'furnitureGoes' ? 'furnitureGoes' : 'furnitureStays';

        // Check if the operation is within the same list
        const isSameList = sourceList === destinationList;

        const item = calculation.inventory.rooms[selectedRoomIndex][sourceList][source.index];

        if (isSameList) {
            // Operation within the same list
            const newList = [...calculation.inventory.rooms[selectedRoomIndex][sourceList]];
            newList.splice(source.index, 1); // Remove the item from its original position
            newList.splice(destination.index, 0, item); // Insert the item at its new position

            // Update the state with the reordered list
            onFormValueChangeWReset(`inventory.rooms.${selectedRoomIndex}`, 'set', {
                ...calculation.inventory.rooms[selectedRoomIndex],
                [sourceList]: newList
            });
        } else {
            // Operation between different lists
            const newSourceList = [...calculation.inventory.rooms[selectedRoomIndex][sourceList]];
            newSourceList.splice(source.index, 1); // Remove from source list

            const newDestinationList = [...calculation.inventory.rooms[selectedRoomIndex][destinationList]];
            newDestinationList.splice(destination.index, 0, item); // Insert into destination list

            // Update the state with the new lists
            onFormValueChangeWReset(`inventory.rooms.${selectedRoomIndex}`, 'set', {
                ...calculation.inventory.rooms[selectedRoomIndex],
                [sourceList]: newSourceList,
                [destinationList]: newDestinationList
            });
        }
    };

    const removeItem = (type, index) => {
        const room = JSON.parse(JSON.stringify(calculation.inventory.rooms[selectedRoomIndex]));
        if (type === "Goes") {
            const furnitureGoes = room.furnitureGoes.filter((item, i) => i !== index);
            onFormValueChangeWReset(`inventory.rooms.${selectedRoomIndex}.furnitureGoes`, 'set', furnitureGoes);
        } else {
            const furnitureStays = room.furnitureStays.filter((item, i) => i !== index);
            onFormValueChangeWReset(`inventory.rooms.${selectedRoomIndex}.furnitureStays`, 'set', furnitureStays);
        }
        setItemChangeCount(prevCount => prevCount + 1);
    }

    const addGoesItemButtonRef = useRef(null);
    const addStaysItemButtonRef = useRef(null);


    useEffect(() => {
        // Function to handle keydown events
        const handleKeyDown = (event) => {
            // Ctrl + Shift + 1
            if (event.ctrlKey && event.shiftKey && event.keyCode === 49) {
                // Prevent default action to avoid any unwanted behavior
                event.preventDefault();
                // Focus the "Add item" button
                if (addGoesItemButtonRef.current) {
                    addGoesItemButtonRef.current.focus();
                }
            }

            // Ctrl + Shift + @ (which is Shift + 2 on US keyboards)
            if (event.ctrlKey && event.shiftKey && event.keyCode === 50) {
                // Prevent default action to avoid any unwanted behavior
                event.preventDefault();
                // Focus the "Add item" button
                if (addStaysItemButtonRef.current) {
                    addStaysItemButtonRef.current.focus();
                }
            }
        };

        // Add event listener
        document.addEventListener('keydown', handleKeyDown);

        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const [goesCuFtRefs, setGoesCuFtRefs] = useState([]);
    const [staysCuFtRefs, setStaysCuFtRefs] = useState([]);
    const [lastModifiedGoesIndex, setLastModifiedGoesIndex] = useState(null);
    const [lastModifiedStaysIndex, setLastModifiedStaysIndex] = useState(null);

    useEffect(() => {
        if (lastModifiedGoesIndex !== null) {
            setLastModifiedStaysIndex(null);
        }
    }, [lastModifiedGoesIndex]);

    // Effect to monitor changes to lastModifiedStaysIndex
    useEffect(() => {
        if (lastModifiedStaysIndex !== null) {
            setLastModifiedGoesIndex(null);
        }
    }, [lastModifiedStaysIndex]);


    useEffect(() => {
        // Adjust refs array to match the furnitureGoes array length
        if (typeof selectedRoomIndex === 'number') {
            const newRefs = calculation.inventory.rooms[selectedRoomIndex].furnitureGoes.map(() => React.createRef());
            setGoesCuFtRefs(newRefs);
        }
    }, [calculation.inventory?.rooms, lastModifiedGoesIndex, selectedRoomIndex]);

    useEffect(() => {
        if (typeof selectedRoomIndex === 'number') {
            const newRefs = calculation.inventory.rooms[selectedRoomIndex].furnitureStays.map(() => React.createRef());
            setStaysCuFtRefs(newRefs);
        }
    }, [calculation.inventory?.rooms, lastModifiedStaysIndex, selectedRoomIndex]);


    useEffect(() => {
        if (typeof lastModifiedGoesIndex === 'number' && goesCuFtRefs[lastModifiedGoesIndex] && goesCuFtRefs[lastModifiedGoesIndex].current) {
            goesCuFtRefs[lastModifiedGoesIndex].current.focus();
        }
    }, [goesCuFtRefs, lastModifiedGoesIndex]);


    useEffect(() => {
        if (typeof lastModifiedStaysIndex === 'number' && staysCuFtRefs[lastModifiedStaysIndex] && staysCuFtRefs[lastModifiedStaysIndex].current) {
            staysCuFtRefs[lastModifiedStaysIndex].current.focus();
        }
    }, [staysCuFtRefs, lastModifiedStaysIndex]);

    const [totalValues, setTotalValues] = useState({});
    const [currentRoomCuFt, setCurrentRoomCuFt] = useState(0);

    useEffect(() => {
        let localCurrentRoomCuFt = 0;
        if (selectedRoomIndex !== null) {
            calculation.inventory.rooms[selectedRoomIndex].furnitureGoes.forEach((furnitureGoesItem) => {
                localCurrentRoomCuFt += furnitureGoesItem.cuft * furnitureGoesItem.qty;
            });
            calculation.inventory.rooms[selectedRoomIndex].boxes.forEach((box, index) => {
                localCurrentRoomCuFt += box.fragile * 1.5 + box.small * 1.5 + box.medium * 3 + box.WB * 10;
            })
        }
        setCurrentRoomCuFt(localCurrentRoomCuFt)
    }, [selectedRoomIndex, calculation.inventory])

    useEffect(() => {

        let cuftPerStop = [];
        let totalFurnitureAndWB = 0;
        let totalFragileQty = 0;
        let totalSmallQty = 0;
        let totalMediumQty = 0;
        let totalWBQty = 0;
        let totalBoxesCuFt = 0;


        calculation.inventory?.extraStops.map((stop, index) => {
            let totalFurniture = 0;
            let totalFragile = 0;
            let totalSmall = 0;
            let totalMedium = 0;
            let totalWB = 0;
            let totalCuFt = 0;
            let totalBoxesCubes = 0
            let adjustedCuFt = 0;


            calculation.inventory.rooms.forEach((room, roomIndex) => {
                room.furnitureGoes.forEach((furnitureGoesItem) => {
                    if (furnitureGoesItem.stopIndex == index) {
                        totalFurniture += furnitureGoesItem.cuft * furnitureGoesItem.qty;
                    }
                });
                room.boxes[index] && (totalFragile += Number(room.boxes[index].fragile));
                room.boxes[index] && (totalSmall += Number(room.boxes[index].small));
                room.boxes[index] && (totalMedium += Number(room.boxes[index].medium));
                room.boxes[index] && (totalWB += Number(room.boxes[index].WB));

            });
            totalCuFt = totalFurniture + totalFragile * 1.5 + totalSmall * 1.5 + totalMedium * 3 + totalWB * 10;
            totalFurnitureAndWB += totalFurniture + totalWB * 10;
            totalFragileQty += Number(totalFragile);
            totalSmallQty += Number(totalSmall);
            totalMediumQty += Number(totalMedium);
            totalWBQty += Number(totalWB);
            totalBoxesCuFt += totalFragile * 1.5 + totalSmall * 1.5 + totalMedium * 3;

            cuftPerStop.push({ totalFurniture, totalFragile, totalSmall, totalMedium, totalWB, totalCuFt });
        });


        setTotalValues({ cuftPerStop, totalFurnitureAndWB, totalFragileQty, totalSmallQty, totalMediumQty, totalWBQty, totalBoxesCuFt });
    }, [calculation.inventory?.rooms]);


    return (
        <div>
            {!calculation.calculationNumber ? (
                <p className={styles.warningMessage}>Calculation number is required to manage inventory</p>
            ) : (
                <div className={styles.inventoryContainer}>

                    <div className={styles.listColumn}>

                        <div className={styles.listColumnWORN}>
                            <span style={{ marginBottom: !hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR]) ? '40px' : '0px' }}>Inventory</span>
                            {(hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR])) ? <>
                                <BtnSubmit
                                    isActive={true}
                                    action={() => { setUpdateableSave(false); formSubmit() }}
                                    name={'Save'}
                                    style={{ marginRight: '0px' }}
                                />    </>
                                : ''}

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {furniture && !furniture?.inventoryListOptions?.[selectedDepartment]?.includeCuFtPerItem && <>
                                    <input
                                        type="checkbox"
                                        checked={calculation?.inventory?.cuFtOption?.include}
                                        onChange={event => onFormValueChangeWReset(`inventory.cuFtOption`, 'set', { include: event.target.checked, remove: !event.target.checked })}
                                    />
                                    <p className={styles.inputName}>Yes - Cu Ft per item</p>
                                </>
                                }
                                {furniture && furniture?.inventoryListOptions?.[selectedDepartment]?.includeCuFtPerItem && <>
                                    <input
                                        type="checkbox"
                                        checked={calculation?.inventory?.cuFtOption?.remove}
                                        onChange={event => onFormValueChangeWReset(`inventory.cuFtOption`, 'set', { include: !event.target.checked, remove: event.target.checked })}
                                    />
                                    <p className={styles.inputName}>No - Cu Ft per item</p>
                                </>
                                }
                            </div>

                            {(hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])) ? <>
                                <BtnSubmit
                                    isActive={true}
                                    action={(evt) => { applyInventory(evt, totalValues) }}
                                    name={'Apply Inventory'}
                                />
                            </>
                                : ''}
                            <hr className={styles.hLine} style={{ marginLeft: '0px' }} />


                            {showLoader && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: '100%' }}><span>AutoSaving</span><Loading style={{ margin: '5px' }} /></div>}
                            {(hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])) ?
                                <div className={styles.buttonContainer}>
                                    <button
                                        className={styles.extraBtn}
                                        onClick={
                                            (evt) => {
                                                evt.preventDefault();
                                                addRoom(calculation.inventory.rooms.length);
                                            }
                                        }
                                    >
                                        <Icon name={IconNames.PLUS} />
                                        Add room
                                    </button>
                                </div>
                                : ''}
                        </div>
                        <div className={styles.roomList} >
                            {calculation.inventory && calculation.inventory?.rooms.map((room, index) => (
                                <div
                                    key={index}
                                    className={(selectedRoomIndex == index) ? styles.highlightedRoomStyle : styles.roomStyle}
                                    onClick={() => setSelectedRoomIndex(index)}
                                    style={{ cursor: 'pointer', borderRadius: '4px' }}
                                >
                                    {room.roomName}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.detailsColumn}>
                        <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", width: '100%', marginTop: '5px' }}>
                            <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>EXTRA STOPS</span>
                                    {(hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])) ?
                                        <button
                                            className={styles.extraStopBtn}
                                            onClick={
                                                (evt) => {
                                                    evt.preventDefault();
                                                    addExtraStop();
                                                }
                                            }
                                        >
                                            <span style={{ marginRight: '5px' }} >Add extra stop</span>
                                            <Icon name={IconNames.PLUS} />
                                        </button>
                                        : ''}
                                </div>
                            </div>

                            <div style={{
                                backgroundColor: '#d0e8f3', padding: '5px',
                                borderBottomLeftRadius: '10px',
                                borderBottomRightRadius: '10px',
                            }}>

                                <div style={{ display: 'flex', flexWrap: 'wrap', maxHeight: '70px', overflow: 'auto' }}>
                                    {calculation.inventory?.extraStops.map((stop, index) => {
                                        return (
                                            <div style={{ display: 'flex', marginLeft: '10px', marginTop: '5px' }} key={index + 'ciee'}>
                                                <div className={styles.styledInput}>
                                                    <div className={styles.number} style={{ backgroundColor: stop.color }}>{(index === 0) ? 'M' : index}</div>
                                                    <input type="text" value={stop.name} onChange={evt => changeExtraStopName(evt, index)} disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])} />
                                                </div>

                                                {(hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])) ?
                                                    ((index + 1) === calculation.inventory.extraStops.length) && <button
                                                        className={styles.removeExtraBtnList}
                                                        onClick={
                                                            (evt) => {
                                                                evt.preventDefault();
                                                                removeExtraStop(index)
                                                            }
                                                        }
                                                    >
                                                        <Icon name={IconNames.MINUS} />
                                                    </button>
                                                    : ''}
                                            </div>

                                        )
                                    })}



                                </div>
                            </div>
                        </div>

                        <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", width: '100%', marginTop: '5px', background: 'white', overflow: 'hidden', minHeight: '120px' }}>
                            <div className={styles.resultsContainer}>
                                <div className={styles.resultsColumn} style={{ width: '25%', flex: '1' }}>
                                    <div className={styles.resultsRow} style={{ marginBottom: '10px' }}>
                                        <div>Furniture & WB Cu Ft:</div>
                                        <div>{totalValues.totalFurnitureAndWB}</div>
                                    </div>
                                    <div className={styles.resultsRow}>
                                        <div>Total Wardrobe boxes:</div>
                                        <div>{totalValues.totalWBQty}</div>
                                    </div>
                                    <div className={styles.resultsRow}>
                                        <label className={styles.commonLabel}>
                                            <span>Packing</span>
                                            <select
                                                className={styles.commonInput}
                                                name={'packing'}
                                                value={calculation.inventory?.packing}
                                                onChange={(evt) => onFormValueChangeWReset(`inventory.${evt.target.name}`, 'set', evt.target.value)}
                                                disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                                            >
                                                {setPackingOptions(calculation.calculationType)}
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.resultsColumn} style={{ width: '25%', paddingLeft: '10px', flex: '1' }}>
                                    <div className={styles.resultsRow} style={{ marginBottom: '10px' }} >
                                        <div style={{ fontWeight: 'bold' }}>Boxes Cu Ft:</div>
                                        <div>{totalValues.totalBoxesCuFt}</div>
                                    </div>
                                    <div className={styles.resultsRow}>
                                        <div>Total Fragile Boxes:</div>
                                        <div>{totalValues.totalFragileQty}</div>
                                    </div>
                                    <div className={styles.resultsRow}>
                                        <div>Total Small Boxes:</div>
                                        <div>{totalValues.totalSmallQty}</div>
                                    </div>
                                    <div className={styles.resultsRow}>
                                        <div>Total Medium Boxes:</div>
                                        <div>{totalValues.totalMediumQty}</div>
                                    </div>
                                </div>
                                <div className={styles.resultsColumn} style={{ width: '50%', padding: '5px 10px', marginRight: '0' }}>
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            Adjusted
                                        </div>
                                        <div className={`${styles.resultsRow} ${styles.resultsHighlight}`} style={{ justifyContent: 'flex-end', borderBottom: '1px solid #707070' }}>

                                            <div>Total Cu Ft:</div>
                                            <div style={{ marginLeft: '10px' }}>{totalValues.totalFurnitureAndWB + totalValues.totalBoxesCuFt}</div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', overflow: 'auto' }}>
                                        {calculation.inventory?.extraStops.map((stop, index) => {
                                            return (<div className={styles.cuftDetailsCont} key={index + 'keee2'}>{stop.name}{index == 0 ? '*' : ''}: {(totalValues.cuftPerStop && totalValues.cuftPerStop[index]) ? totalValues.cuftPerStop[index].totalCuFt + ' | ' + Number(totalValues.cuftPerStop[index].totalFurniture + totalValues.cuftPerStop[index].totalWB * 10) + " + " + totalValues.cuftPerStop[index].totalFragile + "F " + totalValues.cuftPerStop[index].totalSmall + "S " + totalValues.cuftPerStop[index].totalMedium + "M" : ''} </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>



                        {(typeof selectedRoomIndex === 'number' && calculation.inventory?.rooms[selectedRoomIndex]) &&
                            <DragDropContext onDragEnd={onDragEnd}>
                                <div style={{ display: 'flex', marginBottom: '5px', flex: 2, overflow: 'hidden', minHeight: '100px' }}>
                                    <div style={{
                                        border: "1px solid #4db6ac", borderRadius: "10px", width: '53%', marginTop: '5px', display: 'flex',
                                        flexDirection: 'column', overflow: 'hidden',
                                    }}>
                                        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '2px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', height: '25px', alignItems: 'center' }}>
                                                <RoomNameInput
                                                    inventory={calculation.inventory}
                                                    onFormValueChange={onFormValueChangeWReset}
                                                    selectedRoomIndex={selectedRoomIndex}
                                                    roomsAreas={roomsAreas}
                                                    currentUserRole={currentUserRole}
                                                />
                                                <span className={styles.goesTitle}>ㅤㅤㅤGOES</span>
                                                <span>{currentRoomCuFt} cu ft .</span>
                                                {(hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])) ?
                                                    <img src={deleteIcon} alt="Delete icon" onClick={(evt) => {
                                                        evt.preventDefault();
                                                        removeRoom(selectedRoomIndex);
                                                    }} style={{ height: "20px", marginRight: '10px' }} />
                                                    : 'ㅤ'}
                                            </div>
                                        </div>
                                        <Droppable droppableId="furnitureGoes">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={{
                                                        backgroundColor: 'white', padding: '5px',
                                                        borderBottomLeftRadius: '10px',
                                                        borderBottomRightRadius: '10px',
                                                        flex: 1, overflowY: 'auto',
                                                        overflowX: 'hidden'
                                                    }}
                                                >

                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div className={styles.furnitureTitle}>
                                                            <span>Furniture</span>
                                                        </div>
                                                        <div style={{ flex: 1, padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <span>CU FT</span>
                                                        </div>
                                                        <div style={{ flex: 1, padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <span>QTY</span>
                                                        </div>
                                                        <div style={{ flex: 1, padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <span>STOP</span>
                                                        </div>
                                                    </div>
                                                    {calculation.inventory.rooms[selectedRoomIndex].furnitureGoes.map((item, itemIndex) => (
                                                        <Draggable
                                                            key={item.name + 'goes' + itemIndex}
                                                            draggableId={item.name + itemIndex}
                                                            index={itemIndex}
                                                            isDragDisabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                                                        >
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}

                                                                >
                                                                    <div key={item.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <div style={{ flex: 1, padding: '5px' }}>
                                                                            <div style={{ display: 'flex' }}>
                                                                                {hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS]) ?
                                                                                    <button
                                                                                        className={styles.removeExtraBtnList}
                                                                                        onClick={
                                                                                            (evt) => {
                                                                                                evt.preventDefault();
                                                                                                removeItem('Goes', itemIndex);
                                                                                            }
                                                                                        }
                                                                                        {...provided.dragHandleProps}
                                                                                    >
                                                                                        <Icon name={IconNames.MINUS} />
                                                                                    </button>
                                                                                    : 'ㅤ'}
                                                                                <FurnitureNameInput
                                                                                    reference={itemIndex === calculation.inventory.rooms[selectedRoomIndex].furnitureGoes.length - 1 ? lastGoesItemRef : null} // Attach ref to last item
                                                                                    value={item.name}
                                                                                    furniture={furniture}
                                                                                    inventory={calculation.inventory}
                                                                                    itemIndex={itemIndex}
                                                                                    onFormValueChange={onFormValueChange}
                                                                                    selectedRoomIndex={selectedRoomIndex}
                                                                                    furnitureType={'furnitureGoes'}
                                                                                    cuFtRef={goesCuFtRefs[itemIndex]}
                                                                                    setLastModifiedIndex={setLastModifiedGoesIndex}
                                                                                    currentUserRole={currentUserRole}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ flex: 1, padding: '5px' }}  {...provided.dragHandleProps} tabIndex={-1}>
                                                                            <CuFtInput value={item.cuft}
                                                                                furnitureItem={furniture.items.find((furnitureItem) => furnitureItem[`itemName${furniture.items.indexOf(furnitureItem)}`] === item.name)}
                                                                                furnitureItemIndex={furniture.items.findIndex((furnitureItem) => furnitureItem[`itemName${furniture.items.indexOf(furnitureItem)}`] === item.name)}
                                                                                onFormValueChange={onFormValueChangeWReset}
                                                                                name='cuft'
                                                                                itemIndex={itemIndex}
                                                                                furnitureType='furnitureGoes'
                                                                                selectedRoomIndex={selectedRoomIndex}
                                                                                cuFtRef={goesCuFtRefs[itemIndex]}
                                                                                hasRole={hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                                                                            />
                                                                        </div>
                                                                        <div style={{ flex: 1, padding: '5px' }}  {...provided.dragHandleProps} tabIndex={-1}>
                                                                            <QuantityInput value={item.qty} name='qty'
                                                                                furnitureType='furnitureGoes'
                                                                                onFormValueChange={onFormValueChangeWReset}
                                                                                itemIndex={itemIndex}
                                                                                selectedRoomIndex={selectedRoomIndex}
                                                                                hasRole={hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                                                                            />
                                                                        </div>
                                                                        <div style={{ flex: 1, padding: '5px', display: 'flex' }}  {...provided.dragHandleProps} tabIndex={-1}>
                                                                            {!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS]) && 'ㅤ'}
                                                                            <StopSelect
                                                                                value={item.stopIndex}
                                                                                onFormValueChange={onFormValueChangeWReset}
                                                                                options={calculation.inventory.extraStops}
                                                                                furnitureType='furnitureGoes'
                                                                                itemIndex={itemIndex}
                                                                                selectedRoomIndex={selectedRoomIndex}
                                                                                name={'stopIndex'}
                                                                                hasRole={hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                                                                            />
                                                                            {hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS]) &&
                                                                                <svg width="20px" height="20px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 8C10.3284 8 11 7.32843 11 6.5C11 5.67157 10.3284 5 9.5 5C8.67157 5 8 5.67157 8 6.5C8 7.32843 8.67157 8 9.5 8ZM9.5 14C10.3284 14 11 13.3284 11 12.5C11 11.6716 10.3284 11 9.5 11C8.67157 11 8 11.6716 8 12.5C8 13.3284 8.67157 14 9.5 14ZM11 18.5C11 19.3284 10.3284 20 9.5 20C8.67157 20 8 19.3284 8 18.5C8 17.6716 8.67157 17 9.5 17C10.3284 17 11 17.6716 11 18.5ZM15.5 8C16.3284 8 17 7.32843 17 6.5C17 5.67157 16.3284 5 15.5 5C14.6716 5 14 5.67157 14 6.5C14 7.32843 14.6716 8 15.5 8ZM17 12.5C17 13.3284 16.3284 14 15.5 14C14.6716 14 14 13.3284 14 12.5C14 11.6716 14.6716 11 15.5 11C16.3284 11 17 11.6716 17 12.5ZM15.5 20C16.3284 20 17 19.3284 17 18.5C17 17.6716 16.3284 17 15.5 17C14.6716 17 14 17.6716 14 18.5C14 19.3284 14.6716 20 15.5 20Z" fill="#121923" />
                                                                                </svg>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                    {(hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])) ?
                                                        <AddItemButton
                                                            addItemButtonRef={addGoesItemButtonRef}
                                                            isPassive={calculation.inventory.rooms[selectedRoomIndex].furnitureGoes.some(item => item.name === "")}
                                                            type='Goes'
                                                            addItem={addItem}
                                                        />
                                                        : ''}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>

                                    <div style={{
                                        border: "1px solid #4db6ac", borderRadius: "10px", width: '47%', marginTop: '5px', marginLeft: '5px', display: 'flex',
                                        flexDirection: 'column', overflow: 'hidden',
                                    }}
                                    >
                                        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '2px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', height: '25px', alignItems: 'center' }}>
                                                <span className={styles.staysTitle}>STAYS</span>
                                            </div>
                                        </div>
                                        <Droppable droppableId="furnitureStays">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={{
                                                        backgroundColor: 'white', padding: '5px',
                                                        borderBottomLeftRadius: '10px',
                                                        borderBottomRightRadius: '10px',
                                                        flex: 1, overflowY: 'auto',
                                                        overflowX: 'hidden'
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div className={styles.furnitureTitle}>
                                                            <span>Furniture</span>
                                                        </div>
                                                        <div style={{ flex: 1, padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <span>CU FT</span>
                                                        </div>
                                                        <div style={{ flex: 1, padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <span>QTY</span>
                                                        </div>
                                                    </div>
                                                    {calculation.inventory?.rooms[selectedRoomIndex].furnitureStays.map((item, itemIndex) => (
                                                        <div key={item.name + 'stays' + itemIndex + 'pd'}>
                                                            <Draggable
                                                                key={item.name + 'stays' + itemIndex}
                                                                draggableId={item.name + itemIndex}
                                                                index={itemIndex}
                                                                isDragDisabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                                                            >
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <div key={item.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                            <div style={{ flex: 1, padding: '5px', display: 'flex' }} >
                                                                                {hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS]) ?
                                                                                    <button
                                                                                        className={styles.removeExtraBtnList}
                                                                                        onClick={
                                                                                            (evt) => {
                                                                                                evt.preventDefault();
                                                                                                removeItem('Stays', itemIndex);
                                                                                            }
                                                                                        }
                                                                                        {...provided.dragHandleProps}
                                                                                    >

                                                                                        <Icon name={IconNames.MINUS} />
                                                                                    </button>
                                                                                    : 'ㅤ'}
                                                                                <FurnitureNameInput
                                                                                    reference={itemIndex === calculation.inventory.rooms[selectedRoomIndex].furnitureStays.length - 1 ? lastStaysItemRef : null} // Attach ref to last item
                                                                                    value={item.name}
                                                                                    furniture={furniture}
                                                                                    inventory={calculation.inventory}
                                                                                    itemIndex={itemIndex}
                                                                                    onFormValueChange={onFormValueChange}
                                                                                    selectedRoomIndex={selectedRoomIndex}
                                                                                    furnitureType={'furnitureStays'}
                                                                                    cuFtRef={staysCuFtRefs[itemIndex]}
                                                                                    setLastModifiedIndex={setLastModifiedStaysIndex}
                                                                                    currentUserRole={currentUserRole}
                                                                                />
                                                                            </div>
                                                                            <div style={{ flex: 1, padding: '5px' }}  {...provided.dragHandleProps} tabIndex={-1}>
                                                                                <CuFtInput value={item.cuft}
                                                                                    furnitureItem={furniture.items.find((furnitureItem) => furnitureItem[`itemName${furniture.items.indexOf(furnitureItem)}`] === item.name)}
                                                                                    furnitureItemIndex={furniture.items.findIndex((furnitureItem) => furnitureItem[`itemName${furniture.items.indexOf(furnitureItem)}`] === item.name)}
                                                                                    onFormValueChange={onFormValueChangeWReset} name='cuft' itemIndex={itemIndex} furnitureType='furnitureStays' selectedRoomIndex={selectedRoomIndex}
                                                                                    cuFtRef={staysCuFtRefs[itemIndex]}
                                                                                    hasRole={hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                                                                                />
                                                                            </div>
                                                                            <div style={{ flex: 1, padding: '5px', display: 'flex', alignItems: 'center' }}  {...provided.dragHandleProps} tabIndex={-1}>
                                                                                <QuantityInput value={item.qty} name='qty'
                                                                                    furnitureType='furnitureStays'
                                                                                    onFormValueChange={onFormValueChangeWReset}
                                                                                    itemIndex={itemIndex}
                                                                                    selectedRoomIndex={selectedRoomIndex}
                                                                                    hasRole={hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                                                                                />
                                                                                {hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS]) &&
                                                                                    <svg width="20px" height="20px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 8C10.3284 8 11 7.32843 11 6.5C11 5.67157 10.3284 5 9.5 5C8.67157 5 8 5.67157 8 6.5C8 7.32843 8.67157 8 9.5 8ZM9.5 14C10.3284 14 11 13.3284 11 12.5C11 11.6716 10.3284 11 9.5 11C8.67157 11 8 11.6716 8 12.5C8 13.3284 8.67157 14 9.5 14ZM11 18.5C11 19.3284 10.3284 20 9.5 20C8.67157 20 8 19.3284 8 18.5C8 17.6716 8.67157 17 9.5 17C10.3284 17 11 17.6716 11 18.5ZM15.5 8C16.3284 8 17 7.32843 17 6.5C17 5.67157 16.3284 5 15.5 5C14.6716 5 14 5.67157 14 6.5C14 7.32843 14.6716 8 15.5 8ZM17 12.5C17 13.3284 16.3284 14 15.5 14C14.6716 14 14 13.3284 14 12.5C14 11.6716 14.6716 11 15.5 11C16.3284 11 17 11.6716 17 12.5ZM15.5 20C16.3284 20 17 19.3284 17 18.5C17 17.6716 16.3284 17 15.5 17C14.6716 17 14 17.6716 14 18.5C14 19.3284 14.6716 20 15.5 20Z" fill="#121923" />
                                                                                    </svg>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </Draggable>
                                                        </div>
                                                    ))}
                                                    {provided.placeholder}
                                                    {(hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])) ?
                                                        <AddItemButton
                                                            addItemButtonRef={addStaysItemButtonRef}
                                                            isPassive={calculation.inventory.rooms[selectedRoomIndex].furnitureStays.some(item => item.name === "")}
                                                            type='Stays'
                                                            addItem={addItem}
                                                        />
                                                        : ''}
                                                </div>)}
                                        </Droppable>

                                    </div>
                                </div>
                            </DragDropContext>
                        }

                        <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", width: '100%', marginTop: 'auto', }}>
                            <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>BOXES</span>
                                </div>
                            </div>
                            <div style={{
                                backgroundColor: 'white', padding: '5px',
                                borderBottomLeftRadius: '10px',
                                borderBottomRightRadius: '10px',
                                maxHeight: '150px',
                                overflow: 'auto'
                            }}>

                                <div className={styles.boxesCont}>
                                    {calculation.inventory?.extraStops.map((stop, index) => {
                                        return (
                                            <div className={styles.boxCont} key={'kieee' + index}>
                                                <div style={{ background: (stop.color) ? stop.color : '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <span style={{ color: 'white' }}>STOP - {stop.name}</span>
                                                    </div>
                                                </div>
                                                {selectedRoomIndex !== null &&
                                                    <div style={{ background: 'rgb(237, 244, 247)', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', padding: '5px', display: 'flex', flexDirection: 'column' }}>
                                                        <label style={{ display: 'flex', marginBottom: '5px', justifyContent: 'space-between' }}><span style={{ color: 'red' }}>Fragile</span> <BoxesInput
                                                            onFormValueChange={onFormValueChangeWReset}
                                                            path={`inventory.rooms.${selectedRoomIndex}.boxes.${index}`}
                                                            inputName='fragile'
                                                            boxes={calculation.inventory?.rooms[selectedRoomIndex].boxes[index]}
                                                            value={calculation.inventory?.rooms[selectedRoomIndex].boxes[index].fragile}
                                                            hasRole={hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                                                        />
                                                        </label>
                                                        <label style={{ display: 'flex', marginBottom: '5px', justifyContent: 'space-between' }}>Small  <BoxesInput
                                                            onFormValueChange={onFormValueChangeWReset}
                                                            path={`inventory.rooms.${selectedRoomIndex}.boxes.${index}`}
                                                            inputName='small'
                                                            boxes={calculation.inventory?.rooms[selectedRoomIndex].boxes[index]}
                                                            value={calculation.inventory?.rooms[selectedRoomIndex].boxes[index].small}
                                                            hasRole={hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                                                        /></label>
                                                        <label style={{ display: 'flex', marginBottom: '5px', justifyContent: 'space-between' }}>Medium <BoxesInput
                                                            onFormValueChange={onFormValueChangeWReset}
                                                            path={`inventory.rooms.${selectedRoomIndex}.boxes.${index}`}
                                                            inputName='medium'
                                                            boxes={calculation.inventory?.rooms[selectedRoomIndex].boxes[index]}
                                                            value={calculation.inventory?.rooms[selectedRoomIndex].boxes[index].medium}
                                                            hasRole={hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                                                        /></label>
                                                        <label style={{ display: 'flex', marginBottom: '5px', justifyContent: 'space-between' }}>WB <BoxesInput
                                                            onFormValueChange={onFormValueChangeWReset}
                                                            path={`inventory.rooms.${selectedRoomIndex}.boxes.${index}`}
                                                            inputName='WB'
                                                            boxes={calculation.inventory?.rooms[selectedRoomIndex].boxes[index]}
                                                            value={calculation.inventory?.rooms[selectedRoomIndex].boxes[index].WB}
                                                            hasRole={hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                                                        /></label>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InventoryContainer;