// QuantityInput.jsx
import React, { useEffect } from 'react';
import styles from './CuFtInput.module.css';

const CuFtInput = ({ value, name, onFormValueChange, itemIndex, furnitureType, selectedRoomIndex, furnitureItem, furnitureItemIndex, cuFtRef, hasRole }) => {

  const allowedValues = [0, 3, 5, 7, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530, 540, 550, 560, 570, 580, 590, 600, 610, 620, 630, 640, 650, 660, 670, 680, 690, 700];

  const handleMinus = () => {
    let currentIndex = allowedValues.indexOf(value);
    if (currentIndex === -1) {
      const closestValue = allowedValues.reduce((prev, curr) =>
        (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev)
      );
      currentIndex = allowedValues.indexOf(closestValue);
    }
    if (currentIndex > 0) {
      const newValue = allowedValues[currentIndex - 1];
      onFormValueChange(`inventory.rooms.${selectedRoomIndex}.${furnitureType}.${itemIndex}.${name}`, 'set', newValue);
    }
  }

  const handleChange = (e) => {
    onFormValueChange(`inventory.rooms.${selectedRoomIndex}.${furnitureType}.${itemIndex}.${name}`, 'set', e.target.value)
  }

  const handlePlus = () => {
    let currentIndex = allowedValues.indexOf(value);

    // If the current value is not found, find the closest value
    if (currentIndex === -1) {
      const closestValue = allowedValues.reduce((prev, curr) =>
        (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev)
      );
      currentIndex = allowedValues.indexOf(closestValue);
    }

    // Proceed with the logic to increment the value if not the last element
    if (currentIndex < allowedValues.length - 1) {
      const newValue = allowedValues[currentIndex + 1];
      onFormValueChange(`inventory.rooms.${selectedRoomIndex}.${furnitureType}.${itemIndex}.${name}`, 'set', newValue);
    }
  }

  const getInputColor = () => {
    if (value == 0 || value == '') {
      return 'rgb(50, 115, 246)'
    } else
      if (furnitureItemIndex > -1) {
        if (Number(value) < Number(furnitureItem[`rangeMin${furnitureItemIndex}`])) {
          return 'green'; // Value is less than rangeMin
        } else if (Number(value) > Number(furnitureItem[`rangeMax${furnitureItemIndex}`])) {
          return 'red'; // Value is greater than rangeMax
        }
        return 'white'; // Default color
      }
  };

  const handleKeyDown = (event) => {
    event.preventDefault();
    if (event.key === 'ArrowUp') {
      handlePlus();
    } else if (event.key === 'ArrowDown') {
      handleMinus();
    }
  };

  return (
    <div className={styles.buttonContainer}>
      <div className={`${styles.button} ${!hasRole ? styles.buttonDisabled : ''}`} onClick={hasRole ? handleMinus : null}>
        <svg className={styles.svgIcon} viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg" >
          <rect x="4" y="11" width="16" height="2" />
        </svg>
      </div>
      <input type="number" className={styles.inputBox} value={value} onChange={handleChange}
        style={{ backgroundColor: getInputColor() }}
        onDragStart={(e) => e.preventDefault()} // Prevent drag start
        onDragOver={(e) => e.preventDefault()} // Prevent drag over
        onSelectStart={(e) => e.preventDefault()} // Prevent select start, which can help prevent drag initiation
        draggable={false} // Prevent drag
        onKeyDown={handleKeyDown}
        ref={cuFtRef}
        disabled={!hasRole}
      />
      <div className={`${styles.button} ${!hasRole ? styles.buttonDisabled : ''}`} onClick={hasRole ? handlePlus : null}>
        <svg className={styles.svgIcon} viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
          <rect x="11" y="4" width="2" height="16" />
          <rect x="4" y="11" width="16" height="2" />
        </svg>
      </div>
    </div >
  );
};

export default CuFtInput;