import React from 'react';
import styles from "./ProceduresResults.module.css";

const ProceduresResults = ({ commonValues, department, highLightItem, scrollRefs, procedures, day }) => {
  if (!((commonValues.procedures.length > 0 && commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '')) && (commonValues.procedures[0].procedureType !== "None" && commonValues.procedures[0].procedureType !== "") && procedures)) {
    return null;
  }

  return (
    <li className={styles.paramsItem}>
      <p className={styles.paramsName}>Procedures:</p>
      <span className={styles.paramsDecor}></span>
      <p className={styles.paramsValue}></p>
      <ul>
        {
          commonValues.procedures.map((procedure, pkIndex) => {
            if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
              return (
                <li key={pkIndex}>
                  <span className={`${(highLightItem === `pIdName${day}_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`pIdName${day}_${procedure.uid}`]}>
                    {procedure.procedureType}
                  </span> x
                  <span className={`${(highLightItem === `pIdQty${day}_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`pIdQty${day}_${procedure.uid}`]}>
                    {procedure.numberOfProcedures}
                  </span>:
                  {
                    (Object.keys(department).length !== 0) && department.packing.cratings.map((crating, index) => {
                      if (procedure.procedureType === crating[`crateName${index}`]) {
                        return (
                          <span className={`${(highLightItem === `pIdCost${day}_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`pIdCost${day}_${procedure.uid}`]}>
                            ${(Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]))}
                          </span>
                        );
                      } else {
                        return '';
                      }
                    })
                  }
                </li>
              );
            } else {
              return '';
            }
          })
        }
      </ul>
    </li>
  );
};

export default ProceduresResults;