import React from 'react';
import styles from "./PackingSuppliesOnTheGo.module.css";

const PackingSuppliesOnTheGo = ({ department, scrollRefs, highLightItem = '', commonValues, isCuFtDepartment = false }) => {


    const renderSuppliesPrices = () => {
        return (
            <>
                <li>
                    Small box: <span className={`${highLightItem === 'smallBox' ? styles.highLighted : ''}`} ref={scrollRefs?.current['smallBox']}>${department.packing.supplies.smallBox}</span>
                </li>
                <li>
                    Medium box: <span className={`${highLightItem === 'mediumBox' ? styles.highLighted : ''}`} ref={scrollRefs?.current['mediumBox']}>${department.packing.supplies.mediumBox}</span>
                </li>
                <li>
                    Large box: <span className={`${highLightItem === 'largeBox' ? styles.highLighted : ''}`} ref={scrollRefs?.current['largeBox']}>${department.packing.supplies.largeBox}</span>
                </li>
                <li>
                    Roll of packing paper: <span className={`${highLightItem === 'rollOfPaper' ? styles.highLighted : ''}`} ref={scrollRefs?.current['rollOfPaper']}>${department.packing.supplies.packingPapper}</span>
                </li>
                <li>
                    Roll of bubble wrap: <span className={`${highLightItem === 'rollOfBubble' ? styles.highLighted : ''}`} ref={scrollRefs?.current['rollOfBubble']}>${department.packing.supplies.bubbleWrap}</span>
                </li>
            </>
        )
    }

    return (
        <div>

            {(commonValues.packing !== "No" && (isCuFtDepartment ? department.packing.packingKits.enabled : true)) ?
                <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>Packing Supplies On The Go:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}>
                    </p>
                    <ul>
                        {!isCuFtDepartment && <>{renderSuppliesPrices()}</>}
                        {department.packing.supplies.calculationMethod === 'suppliesPrices' && <>{renderSuppliesPrices()}</>}
                        {(department.packing.supplies.calculationMethod === 'perBox') && <>
                            <li>
                                <span className={`${(highLightItem === 'perBoxPrice') ? styles.highLighted : ""}`} ref={scrollRefs?.current['perBoxPrice']}>Per 1 packed box price: ${department.packing.supplies.perOnePackedBox}</span>
                            </li>
                        </>}
                    </ul>
                </li>
                : ''}
        </div>
    );
};

export default PackingSuppliesOnTheGo;