
// Styles
import styles from './SocketOverlay.module.css';

export 
const SocketOverlay = () => {
  return (
    <div className={styles.overlay} >
      <div className={styles.message}>
        <p>Connecting...</p>
        <p className={styles.subMessage}>Please check your internet connection</p>
      </div>
    </div>
  );
};