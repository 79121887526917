import React, { useEffect, useState } from 'react';
import socketio from 'socket.io-client';
import { SOCKET_URL } from '../util/const';
import { SocketOverlay } from '../Components/socket-overlay/Socket-overlay';

export const SocketContext = React.createContext();
let socket;

const configureSocket = () => {
  socket = socketio.connect(SOCKET_URL, {
    reconnection: true,            // Enable reconnection
    reconnectionAttempts: Infinity, // Number of reconnection attempts (infinite)
    reconnectionDelay: 2000,       // Delay between reconnection attempts in milliseconds
  });

  socket.on('connect', () => {
    console.log('Connected to the server');
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from the server');
  });

  socket.on('reconnect', (attemptNumber) => {
    console.log(`Reconnected to the server (attempt ${attemptNumber})`);
  });

  // Add other event listeners and custom socket.io logic here

  return socket;
};

export const getSocket = () => {
  return socket;
};

export const disconnectSocket = () => {
  if (socket && socket.connected) {
    socket.disconnect();
    console.log('Manually disconnected from the server');
  }
};

export const connectSocket = () => {
  if (socket && !socket.connected) {
    socket.connect();
    console.log('Manually reconnected to the server');
  }
};

export const SocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(socket && socket.connected);

  useEffect(() => {
    configureSocket();

    const handleConnect = () => {
      setIsConnected(true);
    };

    const handleDisconnect = () => {
      setIsConnected(false);
    };

    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);

    return () => {
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {!isConnected && <SocketOverlay />}
      {children}
    </SocketContext.Provider>
  );
};
